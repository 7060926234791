import LINKS from 'utils/constants/links';

export const FOOTER_MENU_LINKS = [
  {
    HREF: LINKS.ADD_PROJECT.HREF,
    TITLE: LINKS.ADD_PROJECT.TITLE,
  },
  {
    HREF: LINKS.ADD_USER.HREF,
    TITLE: LINKS.ADD_USER.TITLE,
  },
  {
    HREF: LINKS.ADD_SOW.HREF,
    TITLE: LINKS.ADD_SOW.TITLE,
  },
  {
    HREF: LINKS.ADD_WORKFLOW_TEMPLATE.HREF,
    TITLE: LINKS.ADD_WORKFLOW_TEMPLATE.TITLE,
  },
];
